<template lang="pug">
include ../../../configs/template.pug
div.row
  div(v-if="checkAccess('sailorSQCStatement', 'showPayment', statement)").col-12
    +select('body.is_payed', 'paymentStatus', 'payment', 'nameLang')(item-value="status")
  div(v-if="checkAccess('sailorSQCStatement', 'showStatuses', statement) || checkAccess('sailorSQCStatement', 'showStudentStatuses', statement)").col-12
    +select('body.status_document', 'statusesList', 'status', 'nameLang')
  div(v-if="(checkAccess('sailorSQCStatement', 'showStatuses', statement) && body.status_document=== STATUSES_STATEMENT_CONSTANTS.SAILOR.SQC.REJECTED) || showVerificationRefusalReasons").col-12
    Reject
  FileDropZone(
    v-if="checkAccess('sailorSQCStatement', 'requiredFile', statement) && body.status_document!== STATUSES_STATEMENT_CONSTANTS.SAILOR.SQC.REJECTED"
    :error-messages="$valid.validation($v.mediaFilesArray, ['required'])"
    ref="mediaContent").col-12.mt-1.pa-0
  div.col-12.mt-3.d-flex.justify-content-around
    v-btn(
      v-if="checkAccess('sailorSQCStatement', 'showSuccessButton', statement) && body.status_document!== STATUSES_STATEMENT_CONSTANTS.SAILOR.SQC.REJECTED"
      @click="saveNewSolution()"
      :loading="buttonLoader"
      color="success") {{ checkAccess('sailorSQCStatement', 'showSaveLabel', statement) ? $t('save') : $t('setVerify') }}

</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { sailorStatementSQCEditingStatus } from '@/mixins/validationRules'
import { mapState, mapGetters, mapActions } from 'vuex'
import { STATUSES_STATEMENT_CONSTANTS, SUCCESS_CODE } from '@/configs/constants'

export default {
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue'),
    Reject: () => import('./Reject.vue')
  },
  data () {
    return {
      STATUSES_STATEMENT_CONSTANTS,
      checkAccess,
      buttonLoader: false,
      buttonLoaderReject: false,
      showVerificationRefusalReasons: false,
      files: [],
      body: {
        is_payed: null,
        status_document: this.statement.status_document
      }
    }
  },
  computed: {
    ...mapGetters(['paymentStatusByStatus', 'statusChoose', 'statusById']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      paymentStatus: state => state.directory.paymentStatus
    }),
    documentID () {
      return this.$route.params.documentID
    },
    statusesList () {
      if (checkAccess('sailorSQCStatement', 'showStatuses', this.statement)) {
        let statuses = this.statusChoose('StatementDKK&Qual')
        if (checkAccess('backOffice')) {
          statuses.push(this.statusById(58))
          statuses.push(this.statusById(74))
        }
        return statuses
      } else if (checkAccess('sailorSQCStatement', 'showStudentStatuses', this.statement)) {
        return this.statusChoose('CadetsStatementDKK')
      } else return []
    },
    mediaFilesArray () {
      if (checkAccess('sailorSQCStatement', 'requiredFile', this.statement)) return this.$refs.mediaContent.filesArray
      else return []
    }
  },
  validations () { return sailorStatementSQCEditingStatus(this) },
  mounted () {
    this.body.is_payed = this.paymentStatusByStatus(this.statement.is_payed)[0].status
  },
  methods: {
    ...mapActions(['updateSQCStatements', 'getSQCStatementByID']),

    async saveNewSolution () {
      if (this.$v.$invalid) return this.$v.$touch()
      let data = {
        ...this.$route.params,
        body: { ...this.body },
        media: {
          files: [...this.$refs.mediaContent?.filesArray],
          file_type: 'statement_sqc'
        }
      }
      if (checkAccess('sailorSQCStatement', 'preVerification', this.statement)) {
        data.body.status_document = STATUSES_STATEMENT_CONSTANTS.SAILOR.SQC.IN_PROCESSING
      }
      if (checkAccess('admin')) data.body.status_document = this.body.status_document

      this.buttonLoader = true
      const response = await this.updateSQCStatements(data)
      if (SUCCESS_CODE.includes(response?.code)) this.$notification.success('decisionStatementSQC')
      this.buttonLoader = false
    }
  }
}
</script>
